import ImageOne from '../images/house-1.jpg';
import ImageTwo from '../images/house-2.jpg';
import ImageThree from '../images/house-3.jpg';
import ImageFour from '../images/house-4.jpg';
import ImageFive from '../images/slide-1.jpg';
import ImageSix from '../images/slide-2.jpg';
import ImageSeven from '../images/slide-3.jpg';

export const SliderData = [
  {
    title: 'Luxury Villa in Honolulu, Hawaii',
    price: '$2,880,000 - $3,800,000',
    path: '/homes',
    label: 'View Homes',
    image: ImageOne,
    alt: 'House'
  },
  {
    title: 'House 1',
    price: '$3,800,000',
    path: '/homes',
    label: 'View Homes',
    image: ImageTwo,
    alt: 'House'
  },
  {
    title: 'House 2',
    price: '$2,880,000',
    path: '/homes',
    label: 'View Homes',
    image: ImageThree,
    alt: 'House'
  },
  {
    title: 'House 3',
    price: '$3,200,000',
    path: '/homes',
    label: 'View Homes',
    image: ImageFour,
    alt: 'House'
  }
];

export const SliderDataTwo = [
  {
    title: '',
    price: '',
    path: '/homes',
    label: 'View Homes',
    image: ImageFive,
    alt: 'House'
  },
  {
    title: '',
    price: '',
    path: '/homes',
    label: 'View Homes',
    image: ImageSix,
    alt: 'House'
  },
  {
    title: '',
    price: '',
    path: '/homes',
    label: 'View Homes',
    image: ImageSeven,
    alt: 'House'
  }
];
