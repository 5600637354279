import React from 'react';
import AboutFeatures from '../components/AboutFeatures';
import InfoSection from '../components/InfoSection';
import { InfoDataThree, InfoDataFour } from '../data/InfoData';

const About = () => {
  return( 
    <>
      <InfoSection {...InfoDataThree} />
      <AboutFeatures />
      <InfoSection {...InfoDataFour} />
    </>
  )
};

export default About;
