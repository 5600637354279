import ImageOne from '../images/interior-1.jpg';
import ImageTwo from '../images/house-5.jpg';
import ImageThree from '../images/about-1.jpg'; 
import ImageFour from '../images/about-2.jpg'; 

export const InfoData = {
  heading: 'Stunning Views',
  paragraphOne:
    'The entire villa overlooks the magnificent Diamond Head crater.',
  paragraphTwo:
    'Every morning you will be greeted with the fresh trade winds and crisp Hawaiian mountain breeze.',
  buttonLabel: 'View Homes',
  image: ImageOne,
  reverse: false,
  delay: 100
};

export const InfoDataTwo = {
  heading: 'Modern Designs',
  paragraphOne:
    'The modern architecture of the houses not only brings in a sense of class, but also sustainability.',
  paragraphTwo:
    'All of the houses will be equiped with solar panels to take full advantage of the year round sunshine.',
  buttonLabel: 'View Homes',
  image: ImageTwo,
  reverse: true,
  delay: 300
};

export const InfoDataThree = {
  heading: 'Our Project',
  paragraphOne:
    'Pukalani Villas is a 8 house villa located in hills of Honolulu, Hawaii',
  paragraphTwo:
    'Overlooking the magnificent Diamond Head crater',
  buttonLabel: 'View Homes',
  image: ImageThree,
  reverse: true,
  delay: 300
};

export const InfoDataFour = {
  heading: 'Unit 5, Floor Plan',
  paragraphOne:
    'We would like to welcome you to your new home with the warmest Aloha',
  paragraphTwo:
    'We hope you find everything you\'re looking for and hope to talk to you soon.',
  buttonLabel: 'View Homes',
  image: ImageFour,
  reverse: true,
  delay: 300
};


